import React from "react";
import theme from "theme";
import { Theme, Icon, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaCarSide } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Anasayfa | Velocity Wheels Araç Kiralama
			</title>
			<meta name={"description"} content={"Size benzersiz bir araç kiralama deneyimi sunmak için güvenilirlik, seçim ve olağanüstü hizmeti harmanladığımız Velocity Wheels Araç Kiralama'ya hoş geldiniz."} />
			<meta property={"og:title"} content={"Velocity Wheels Araç Kiralama"} />
			<meta property={"og:description"} content={"Size benzersiz bir araç kiralama deneyimi sunmak için güvenilirlik, seçim ve olağanüstü hizmeti harmanladığımız Velocity Wheels Araç Kiralama'ya hoş geldiniz."} />
			<meta property={"og:image"} content={"https://gemrassa.com/img/0.jpg"} />
			<link rel={"shortcut icon"} href={"https://gemrassa.com/img/2389246.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://gemrassa.com/img/2389246.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://gemrassa.com/img/2389246.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://gemrassa.com/img/2389246.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://gemrassa.com/img/2389246.png"} />
			<meta name={"msapplication-TileImage"} content={"https://gemrassa.com/img/2389246.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Hero-7">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="120px 50px 120px 50px"
					lg-width="100%"
					lg-padding="80px 50px 90px 50px"
					sm-padding="80px 25px 90px 25px"
					lg-display="flex"
					lg-flex-direction="column"
				>
					<Icon
						category="fa"
						icon={FaCarSide}
						size="35px"
						color="--primary"
						margin="0px 0px 45px 0px"
					/>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Velocity Wheels Araç Kiralama
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
						Size benzersiz bir araç kiralama deneyimi sunmak için güvenilirlik, seçim ve olağanüstü hizmeti harmanladığımız Velocity Wheels Araç Kiralama'ya hoş geldiniz. İster iş için seyahat ediyor olun, ister bir yolculuğa çıkın ya da sadece geçici bir sürüşe ihtiyacınız olsun, sizin için mükemmel bir aracımız var.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="--color-primary"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="--primary"
							hover-color="--darkL1"
							hover-background="rgba(63, 36, 216, 0)"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
						>
							Bize Ulaşın
						</Button>
					</Box>
				</Box>
				<Image
					src="https://gemrassa.com/img/0.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Text as="h2" font="--headline2" margin="0 0 24px 0">
				Neden Velocity Wheels'i Seçmelisiniz?
			</Text>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline3" margin="12px 0">
							Çeşitli Filo
						</Text>
						<Text as="p" font="--base" margin="12px 0" color="--greyD2">
							Ekonomik seyahat edenler için ekonomik araçlardan daha lüks bir deneyim için lüks modellere kadar, çeşitli filomuz tüm tercihlere ve ihtiyaçlara hitap eder.
						</Text>
					</Box>
				</Box>
				<Box width="33.333%" padding="16px 16px 16px 16px" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline3" margin="12px 0">
							Rekabetçi Fiyatlar
						</Text>
						<Text as="p" font="--base" margin="12px 0" color="--greyD2">
							Kiralamanız için en iyi değeri almanızı sağlayan şeffaf fiyatlandırma ve rekabetçi fiyatların keyfini çıkarın.
						</Text>
					</Box>
				</Box>
				<Box width="33.333%" padding="16px 16px 16px 16px" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline3" margin="12px 0">
							Ödünsüz Kalite
						</Text>
						<Text as="p" font="--base" margin="12px 0" color="--greyD2">
							Filomuzdaki her aracın bakımı ve servisi, güvenli ve konforlu bir yolculuk sağlamak için titizlikle yapılır.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://gemrassa.com/img/1.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Benzersiz Avantajlar
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Sorunsuz Rezervasyon Süreci - Basit rezervasyon sürecimiz aracınızı hızlı ve kolay bir şekilde rezerve etmenizi sağlar.
Kişiselleştirilmiş Müşteri Hizmetleri - Özel ekibimiz size yardımcı olmak için burada, baştan sona sorunsuz bir kiralama deneyimi sağlıyor.
				</Text>
			</Box>
		</Section>
		<Section sm-padding="60px 0 60px 0" padding="65px 0 65px 0" quarkly-title="Images-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
				>
					<Image
						left={0}
						min-height="100%"
						top={0}
						right={0}
						bottom={0}
						src="https://gemrassa.com/img/2.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://gemrassa.com/img/3.jpg"
						object-fit="cover"
						width="100%"
						top={0}
						position="absolute"
						display="block"
						left={0}
						right={0}
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
			>
				<Box
					width="100%"
					overflow-x="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top="auto"
						src="https://gemrassa.com/img/4.jpg"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="25%"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					lg-text-align="center"
					sm-margin="30px 0px 0px 0px"
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					md-text-align="left"
				>
					Velocity Wheels Araç Kiralama - Her Sürüşün Bir Macera Olduğu Yer
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});